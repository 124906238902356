import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './afk-guide.scss';
import { AFKItem } from '../../../modules/afk/common/components/afk-item';

const AFKGuidesBanner: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page afk-journey afk-guide'}
      game="afk"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/afk-journey/">AFK Journey</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/afk-journey/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Banners and rates</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/afk/categories/category_gacha.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>AFK Journey Banners and rates</h1>
          <h2>Banners and rates information in AFK Journey.</h2>
          <p>
            Last updated: <strong>13/10/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Gacha" />
        <p>
          Currently, there are three types of banners available in the game and
          you can find information about them below.
        </p>
        <h5>All-Hero Recruitment</h5>
        <StaticImage
          src="../../../images/afk/generic/beginner_10.webp"
          alt="Guides"
        />
        <p>
          This is the standard banner of the game where from you can get every
          non-Celestial/Hypogean character available in the game. You can pull
          on this banner using <AFKItem name="Invite Letter" /> and{' '}
          <AFKItem name="Diamonds" />. It costs 300 Diamonds to do a single
          summon, but when you do 10 of them at the same time it costs 2700 - so
          you get a discount. So don't do single pulls with Diamonds.
        </p>
        <p>
          After doing 30 pulls, you will unlock the{' '}
          <strong>Wishlist System</strong> that will allow you to ‘favorite’ two{' '}
          <AFKItem name="S-Level" /> and two <AFKItem name="A-Level" />{' '}
          characters per faction - after filling the wishlist, every character
          you will pull will match those on the wishlist. This helps you narrow
          down the team you want to run.
        </p>
        <p>
          As for the <strong>pity</strong> on this banner. It{' '}
          <strong>starts at 10 pulls</strong> and increases every time you pull
          an <AFKItem name="S-Level" /> character - the pity stops increasing at{' '}
          <strong>60 pulls</strong>. Also, if you're doing a 10-pull, you will
          always obtain at least one A-level character.
        </p>
        <p>Rates:</p>
        <ul>
          <li>
            <AFKItem name="S-Level" /> hero -{' '}
            <strong className="green">
              2.05% (including pity) - 0.72% (base rate)
            </strong>
          </li>
          <li>
            <AFKItem name="A-Level" /> hero -{' '}
            <strong className="green">
              22.5% (including pity) - 20.68% (base rate)
            </strong>
          </li>
          <li>
            Faction Acorn x30 - <strong className="green">0.5%</strong>
          </li>
          <li>
            Faction Acorn x4 - <strong className="green">15%</strong>
          </li>
          <li>
            Faction Acorn x1 - <strong className="green">59.95%</strong>
          </li>
        </ul>
        <h5>Epic Recruitment</h5>
        <StaticImage
          src="../../../images/afk/generic/beginner_11.webp"
          alt="Guides"
        />
        <p>
          This is the second banner which has better rates and a wishlist
          available from the start, but you can only use special{' '}
          <AFKItem name="Epic Invite Letter" /> to pull - you can obtain the
          Letters from shops, various modes and progressing through the main
          story.
        </p>
        <p>
          Compared to the previous banner, on the Wishlist here you can place
          only 5 characters, but it's unlocked from the moment you get access to
          the banner. The <strong>pity also starts at 10</strong> and increases
          when you pull an S-level character, but it{' '}
          <strong>caps at 30 pulls</strong> here.
        </p>
        <p>The rates for this banner are also way better:</p>
        <ul>
          <li>
            <AFKItem name="S-Level" /> hero -{' '}
            <strong className="green">
              5.22% (including pity) - 3.43% (base rate)
            </strong>
          </li>
          <li>
            <AFKItem name="A-Level" /> hero -{' '}
            <strong className="green">
              18.75% (including pity) - 17.75% (base rate)
            </strong>
          </li>
          <li>
            Faction Acorn x30 - <strong className="green">1%</strong>
          </li>
          <li>
            Faction Acorn x4 - <strong className="green">25%</strong>
          </li>
          <li>
            Faction Acorn x1 - <strong className="green">50.03%</strong>
          </li>
        </ul>
        <h5>Rate-up Recruitment</h5>
        <StaticImage
          src="../../../images/afk/generic/banner_limited.webp"
          alt="Guides"
        />
        <p>
          This is the rate-up banner which will be used to introduce new
          characters to the game (or promote older ones). If the rate-up hero is{' '}
          <AFKItem name="S-Level" />, you are guaranteed to obtain them whenever
          you pull an S-level character. And when the rate-up hero is{' '}
          <AFKItem name="A-Level" />, you will obtain 8 copies of them.
        </p>
        <p>
          In the Rate-up Recruitment, the number of rate-up heroes you can
          obtain is determined by the highest tier of your other heroes:
        </p>
        <StaticImage
          src="../../../images/afk/generic/banner_limited_info.webp"
          alt="Guides"
        />
        <p>
          You can pull on this banner using{' '}
          <strong>Rate-up Invite Letters</strong> and{' '}
          <AFKItem name="Diamonds" />. It costs 300 Diamonds to do a single
          summon and compared to the All-Hero Recruitment, there's no discount
          if you do a 10-pull.
        </p>
        <p>
          As for the pity, it's set to <strong>40</strong> and it never
          increases after you hit it, so it's very good. The rates for this
          banner also are better compared to the All-Hero Recruitment:
        </p>
        <ul>
          <li>
            <AFKItem name="S-Level" /> hero -{' '}
            <strong className="green">
              3% (including pity) - 0.97% (base rate)
            </strong>
          </li>
          <li>
            <AFKItem name="A-Level" /> hero -{' '}
            <strong className="green">
              10% (including pity) - 3% (base rate)
            </strong>
          </li>
          <li>
            Omni Acorn x30 - <strong className="green">1.5%</strong>
          </li>
          <li>
            Omni Acorn x4 - <strong className="green">15%</strong>
          </li>
          <li>
            Omni Acorn x1 - <strong className="green">70.05%</strong>
          </li>
        </ul>
        <h5>Stargaze</h5>
        <StaticImage
          src="../../../images/afk/generic/beginner_12.webp"
          alt="Guides"
        />
        <p>
          This is the only banner from which you can pull{' '}
          <AFKItem name="Celestial" /> and <AFKItem name="Hypogean" />{' '}
          characters, but it will take you quite a while to unlock it. As to get
          access to the Stargaze function you need to do{' '}
          <strong>400 pulls on the previous banners</strong>.
        </p>
        <p>
          You can select any <AFKItem name="Celestial" /> and{' '}
          <AFKItem name="Hypogean" /> you want here and you will have{' '}
          <strong className="green">
            3.25% including pity (1.4% base rate)
          </strong>{' '}
          chance to obtain them with each pull. Other things you can pull from
          the banner are: Diamonds, Dazzling and Ruin Stones (used to upgrade
          Exclusive Equipment) and universal Acorns. The pity is also set at{' '}
          <strong>40</strong> pulls and doesn't increase compared to the
          previous banners.
        </p>
        <p>
          What's important is that{' '}
          <strong className="red">you can't use Diamonds</strong> on this banner
          as you can only pull using <AFKItem name="Stellar Crystal" /> - a rare
          currency you can buy from Guild and Recruitment store (and the amount
          you can buy per month is limited).
        </p>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AFKGuidesBanner;

export const Head: React.FC = () => (
  <Seo
    title="Banners and rates | AFK Journey | Prydwen Institute"
    description="Banners and rates information in AFK Journey."
    game="afk"
  />
);
